export default class DashboardService {
  url = process.env.VUE_APP_BACKEND + "/api" + "/statistics";

  constructor(axios) {
    this.http = axios;
  }

  getStatistics() {
    return this.http.get(this.url);
  }
}
