import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/",
      component: () => import("@/view/layout/Layout.vue"),
      children: [
        {
          path: "/",
          redirect: "/",
          component: () => import("@/view/pages/dashboard/Dashboard.vue"),
          children: [
            {
              path: "/",
              name: "dashboard",
              component: () =>
                import("@/view/pages/dashboard/uploader/Uploader.vue")
            },
            {
              path: "/download",
              name: "download",
              component: () =>
                import("@/view/pages/dashboard/download/Download.vue")
            },
            {
              path: "/uploadSuccess",
              name: "uploadSuccess",
              component: () =>
                import(
                  "@/view/pages/dashboard/upload-success/UploadSuccess.vue"
                )
            }
          ]
        },
        {
          path: "/profile",
          redirect: "/profile/",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              name: "profile",
              path: "/profile/",
              component: () =>
                import("@/view/pages/profile/views/ProfileOverview.vue")
            },
            {
              name: "personalInfo",
              path: "/profile/personalInfo",
              component: () =>
                import("@/view/pages/profile/views/PersonalInformation.vue")
            },
            {
              name: "account",
              path: "/profile/account",
              component: () => import("@/view/pages/profile/views/Account.vue")
            },
            {
              name: "settings",
              path: "/profile/settings",
              component: () => import("@/view/pages/profile/views/Settings.vue")
            },
            {
              name: "changePassword",
              path: "/profile/changePassword",
              component: () =>
                import("@/view/pages/profile/views/ChangePassword.vue")
            },
            {
              path: "/profile/payment",
              redirect: "/profile/payment",
              component: () => import("@/view/pages/profile/views/Pay.vue"),
              children: [
                {
                  name: "payment",
                  path: "/profile/payment",
                  component: () =>
                    import("@/view/pages/profile/views/Payment.vue")
                },
                {
                  name: "confirmPayment",
                  path: "/profile/payment/confirm",
                  component: () =>
                    import("@/view/pages/profile/views/ConfirmPayment.vue")
                }
              ]
            },
            {
              name: "invoices",
              path: "/profile/invoices",
              component: () => import("@/view/pages/profile/views/Payment.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/auth",
      redirect: "/auth/login",
      component: () => import("@/view/pages/auth/Auth.vue"),
      children: [
        {
          name: "login",
          path: "/auth/login",
          component: () => import("@/view/pages/auth/views/Login.vue")
        },
        {
          name: "register",
          path: "/auth/register",
          component: () => import("@/view/pages/auth/views/Register.vue")
        },
        {
          name: "forgotPassword",
          path: "/auth/forgotPassword",
          component: () => import("@/view/pages/auth/views/ForgotPassword.vue")
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/error",
      redirect: "/error/500",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          name: "error404",
          path: "/error/404",
          component: () => import("@/view/pages/error/views/Error-1.vue")
        },
        {
          name: "error500",
          path: "/error/500",
          component: () => import("@/view/pages/error/views/Error-1.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/error/404"
    }
  ]
});
