export default class BlobService {
  constructor(axios) {
    this.http = axios;
  }

  uploadBlockBlob(url, blob) {
    return this.http.put(url, blob, {
      headers: { "x-ms-blob-type": "BlockBlob" }
    });
  }

  completeBlob(url, request, type) {
    return this.http.put(url, request, {
      headers: { "x-ms-blob-content-type": type }
    });
  }
}
