// mutation types
export const SET_SILENT_CALL = "setSilentCall";
export const RESET_SILENT_CALL = "resetSilentCall";

const state = {
  silentCall: false
};

const mutations = {
  [SET_SILENT_CALL](state) {
    state.silentCall = true;
  },
  [RESET_SILENT_CALL](state) {
    state.silentCall = false;
  }
};

export default {
  state,
  mutations
};
