// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: null,
  isAuthenticated: false
};

const mock = {
  email: "admin@demo.com",
  password: "demo",
  token: "mgfi5juf74j"
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      context.commit(SET_AUTH, mock);
      resolve(mock);
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  // eslint-disable-next-line no-unused-vars
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      context.commit(SET_AUTH, mock);
      resolve(mock);
    });
  },
  [VERIFY_AUTH](context) {
    if (localStorage.getItem("id_token")) {
      context.commit(SET_AUTH, mock);
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  // eslint-disable-next-line no-unused-vars
  [UPDATE_PASSWORD](context, payload) {}
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    localStorage.setItem("id_token", user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    localStorage.removeItem("id_token");
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
