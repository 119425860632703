import axios from "axios";
import BlobService from "./blob.service";
import TransferService from "./transfer.service";
import DashboardService from "./dashboard.service";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default class HttpServices {
  constructor(router, store) {
    this._router = router;
    this._store = store;

    this.blobService = new BlobService(this.createAxios(false));
    this.transferService = new TransferService(this.createAxios());
    this.dashboardService = new DashboardService(this.createAxios());
  }

  createAxios(hasInterceptor = true) {
    const http = axios.create();
    if (hasInterceptor) {
      http.interceptors.request.use(
        config => {
          this._store.dispatch(
            this._store.state.app.silentCall
              ? REMOVE_BODY_CLASSNAME
              : ADD_BODY_CLASSNAME,
            "page-loading"
          );
          return config;
        },
        error => {
          this._store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          console.error(error);
          return error;
        }
      );

      http.interceptors.response.use(
        response => {
          this._store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          return response;
        },
        error => {
          this._store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          if (
            navigator.onLine &&
            (!error.response || error.response.status === 500)
          ) {
            this._router.push({ name: "error500" });
          }
          console.error(error);
          return error;
        }
      );
    }
    return http;
  }
}
