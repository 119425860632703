export default class TransferService {
  url = process.env.VUE_APP_BACKEND + "/api" + "/transfers";

  constructor(axios) {
    this.http = axios;
  }

  submit(model) {
    const request = {
      from: model.from,
      tos: model.tos,
      message: model.message
    };
    return this.http.post(this.url, request);
  }

  confirm(code, documents) {
    const request = {
      code,
      documents
    };
    return this.http.post(this.url + "/confirm", request);
  }

  complete(transferId) {
    const request = {
      transferId
    };
    return this.http.post(this.url + "/complete", request);
  }

  download(from, to, code) {
    const params = {
      from,
      to,
      code
    };
    return this.http.get(this.url + "/download", { params });
  }
}
